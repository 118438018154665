#b {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 40px;
  vertical-align: top;
  animation-delay: 0.5s;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-name: floatUp;
  animation-timing-function: cubic-bezier(0, 0.71, 0.29, 1)
}

.logo {
  max-height: 200px
}

.logo.is-2x {
  display: none
}

#dropzone * {
  pointer-events: none
}

#tokenContainer,
#panel {
  display: none
}

#maxSize {
  font-size: 1rem
}

.dz-preview .dz-details {
  display: flex
}

.dz-preview .dz-details .dz-size,
.dz-preview .dz-details .dz-filename {
  flex: 1
}

.dz-preview img,
.dz-preview .dz-success-mark,
.dz-preview .dz-error-mark {
  display: none
}

@-webkit-keyframes floatUp {
  0% {
    opacity: 0;
    transform: scale(0.86)
  }

  25% {
    opacity: 100
  }

  67% {
    transform: scale(1)
  }

  100% {
    transform: scale(1)
  }
}

@keyframes floatUp {
  0% {
    opacity: 0;
    transform: scale(0.86)
  }

  25% {
    opacity: 100
  }

  67% {
    transform: scale(1)
  }

  100% {
    transform: scale(1)
  }
}

.uploads {
  display: flex;
  flex-direction: column
}

.uploads.is-reversed {
  flex-direction: column-reverse
}

.uploads > div {
  animation: fadeInOpacity 0.5s;
  margin: 0.75rem !important
}

.uploads.is-reversed > div {
  flex: 0 0 auto
}

.uploads > div:first-child {
  margin-top: 1.5rem
}

.uploads.nojs {
  margin-bottom: 0
}

.uploads > div > .icon:not(.icon-block) {
  color: #209cee
}

.uploads > div > .icon.icon-block {
  color: #da4453
}

.uploads .descriptive-progress {
  color: #bdc3c7
}

.uploads img {
  max-width: 200px
}

.name {
  font-size: 1rem;
  color: #eff0f1;
  word-break: break-all
}

.link > a {
  word-break: break-all
}

.clipboard-mobile {
  margin-top: 5px
}

#albumDiv {
  animation: fadeInOpacity 0.5s
}

#albumDiv .control {
  text-align: inherit
}

#linksColumn {
  margin-top: -0.25rem;
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  animation: fadeInOpacity 0.5s
}

#linksColumn .column {
  padding: 0.25rem
}

#linksColumn > span {
  padding: 0 0.3rem;
  color: #7f8c8d
}

.git-commit a {
  display: inline-block;
  word-break: break-all
}

#tabs {
  margin-bottom: 1rem;
  animation: fadeInOpacity 0.5s
}

#tabs ul {
  border-bottom: 1px solid #585858
}

#tabs li a {
  color: #bdc3c7;
  border-bottom-color: #585858
}

#tabs.is-boxed li.is-active a {
  color: #209cee;
  background: #000;
  border-color: #585858;
  border-bottom-color: #000
}

#tabs.is-boxed li:not(.is-active) a:hover {
  background: #585858
}

.tab-content {
  margin-bottom: -0.75rem;
  animation: fadeInOpacity 0.5s
}

#tab-config.tab-content form {
  margin-bottom: 0.75rem
}

#urlMaxSize {
  font-weight: bold
}

.render {
  position: fixed;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  color: #bdc3c7;
  cursor: pointer
}

.render.button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  right: 1%;
  opacity: 0.25;
  transition: opacity 0.25s
}

.render.button:hover {
  opacity: 1
}

input[type="file"].is-fullwidth {
  width: 100%
}

.kex-button {
    border: solid 1px white;
    border-radius: 5px;
    padding-top: 1em;
    margin: 2em;
    padding-bottom: 1em;
    padding-right: 0.5em;
    padding-left: 0.5em;

    text-decoration: none;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0.5) 50%);
    color: white;

    transition: 0.2s ease-out;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.kex-button:hover {
    -webkit-box-shadow: 0px 0px 20px -2px rgba(255, 255, 255, 1);
    -moz-box-shadow: 0px 0px 20px -2px rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 20px -2px rgba(255, 255, 255, 1);
    background-position: 0 -100%;
    color: black;
}

.kex-button:hover:active {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0.5) 50%);

    -webkit-box-shadow: 0px 0px 20px 3px rgb(255, 255, 255);
    -moz-box-shadow: 0px 0px 20px 3px rgb(255, 255, 255);
    box-shadow: 0px 0px 20px 3px rgb(255, 255, 255);
    background-position: 0 0;
}